/**

 FEATURE FLAGS
 Documentación:https://www.notion.so/talently/Feature-Flags-996e45712ee244c48b4e3dc4098deab0

 Example
 export const REGULAR_SMART_SOURCING_HIRE_COMPANY_CANDIDATES_SEARCH_PAGE =
 'REGULAR_SMART_SOURCING_HIRE_COMPANY_CANDIDATES_SEARCH_PAGE'

 **/

/* -------------------- REGULAR -------------------- */
export const FEAT_CHAT = 'feat_chat'
export const SPRINT_03_2024_COMPANY_OPS_NOTAS_TRS_PARTNER = 'sprint_03_2024_company_ops_notas_trs_partner'
export const SPRINT_06_2024_TALENT_OPS_IMPROVE_OFFER_EXPERIENCE = 'sprint_06_2024_talent_ops_improve_offer_experience'
export const SQUAD_03_FREE_AIMED_SCHEDULING_CALLS = 'squad_03_20240724_free_aimed_scheduling_calls'
export const SQUAD_01_20240722_SEARCH_HIRE_IMPROVE = 'squad_01_20240722_search_hire_improve'
export const SQUAD_03_20240812_PROMOTIONAL_CODES = 'squad_03_20240812_promotional_codes_for_subscriptions'

/* -------------------- MINIS -------------------- */
export const MINI_RULES_FOR_REJECTED_OFFERS = 'mini_rules_for_rejected_offers'
export const MINI_CANDIDATE_MANAGEMENT_LIMIT = 'mini_candidate_management_limit'
export const MINI_ADJUST_PLAN_CANCELLATION_REASONS = 'mini_adjust_plan_cancellation_reasons'
export const MINI_POWER_BANNER_FOR_PRO = 'mini_power_banner_for_pro'
export const MINI_FEATURES_FOR_MY_OFFERS = 'mini_features_for_my_offers'
export const MINI_MOTIVOS_DE_RECHAZO_OFFICE_HIRE = 'sprint_06_2024_mini_motivos_de_rechazo_office_hire'
export const MINI_SEARCH_CANDIDATE_UPDATE = 'mini_search_candidate_update'
export const SQ2_AI_TOP_RECOMMEND = 'sq2_ai_top_recommend'
export const SQ3_FREE_LIMITS = 'sq3_free_limits'

/* -------------------- BUGS -------------------- */

/* -------------------- TESTAB -------------------- */

/* -------------------- CONFIG -------------------- */
export const CONFIG_BANNER_HIRE = 'config_banner_hire'
